import moment from "moment";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import Swal from "sweetalert2";
import { ModalComponent } from "../..";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";
import {
  checkSelectionStatus,
  exist,
  sortArray,
} from "../../../common/helpers";
import { IMAGES } from "../../../constant";
import EmptyStateImg from "../../../images/flight-empty.svg";
import { groupBy } from "../../../utils";
import ExportToEvent from "../../common/ExportToEvent";
import EmptyState from "../../common/emptyState";
import SharingHub from "../../common/sharingHub";
import AddEditFlights from "../../flights/AddEditFlights";
import AddEditPrivateFlights from "../../flights/AddEditPrivateFlights";
import LabelForm from "../../form/LabelForm";
import FlightListItem from "../flightListItem";
import { getFlights } from "../../../page/event/redux/getFlights";
import { useHistory, useLocation } from "react-router-dom";
import { ConsoleNetwork } from "mdi-material-ui";
import { addFlight } from "../../../page/event/redux/addFlight";
import { Spinner } from "reactstrap";
import { useBetaFeature } from "../../../hooks/useBetaFeature";
import { editFlight } from "../../../page/event/redux/editFlight";

const FlightList = (props) => {
  const [state, setState] = useState({
    SelectedItems: [],
    UI: {
      showExportToEvent: false,
      exportMode: "",
      showPrivateFlight: false,
      showCommercialFlight: false,
      showLabel: false,
      showSharingHub: false,
    },
    IsFirstRender: true,
  });

  const GlobalFlights = useSelector((state) => state.event.flights);

  const [Flights, setFlights] = useState([]);
  const history = useHistory();

  const dispatch = useDispatch();

  const shareFlight = async (flight) => {
    try {
      var content = "";
      var count = 0;
      var collection = await db
        .table("flights")
        .where({ Connection: flight.Connection })
        .toArray();

      collection = sortArray(collection, "DepartureDate");
      collection.forEach((item) => {
        if (count > 0) {
          content += "\n";
        }
        if (item.Airline) {
          content += item.AirlineFlight + "\n";
        }

        content += "Depart: " + item.From + "\n";
        content +=
          moment(item.DepartureDate).format("MMM DD, dddd hh:mm a") + "\n";
        content += "Arrival: " + item.To + "\n";
        content +=
          moment(item.ArrivalDate).format("MMM DD, dddd hh:mm a") + "\n";
        if (item.Duration) {
          content += "Duration: " + item.Duration + "\n";
        }
        count++;
      });

      if (
        collection[0].FlightStaffs &&
        collection[0].FlightStaffs.filter((x) => x.Deleted === null)
      ) {
        content += "\n\nOn this flight\n";
        collection[0].FlightStaffs.filter((x) => x.Deleted === null).forEach(
          (member) => {
            content += member.Staff.Name + " " + member.Staff.LastName + "\n";
            if (member.ReservationNumber) {
              content += "\nRSV# " + member.ReservationNumber;
            }
          }
        );
      }
      toggleShare(content);
    } catch (ex) {
      console.error(ex);
    }
  };

  const toggleShowLabel = () => {
    setState((prev) => {
      return {
        ...prev,
        UI: {
          ...prev.UI,
          showLabel: !prev.UI.showLabel,
        },
      };
    });
  };

  const editLabel = async (obj) => {
    try {
      let flights = obj;
      toggleShowLabel();

      dispatch(
        editFlight(flights, () => {
          props.handleSidebarItem("FLIGHT_LIST", obj);
          dispatch(getFlights(flights.EventGUID));
        })
      );
    } catch (ex) {
      console.error(ex);
    }
  };

  const toggleExportToEvent = async (exportMode, reload) => {
    if (state.UI.showExportToEvent === true) {
      await FlightsRemote();
    }
    setState((prev) => {
      return {
        ...prev,
        UI: {
          ...prev.UI,
          showExportToEvent: !prev.UI.showExportToEvent,
          exportMode: exportMode,
        },
        //SelectedItems: []
      };
    });
  };

  const FlightsRemote = async () => {
    try {
      const request = await apiService.get(
        "/Flights/FlightsForEvent?EventGUID=" + props.event.GUID,
        {}
      );
      if (request.data.Status === "ok") {
        let cleaned = request.data.Response;
        await db.table("flights").bulkPut(cleaned);
        // await bindData();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  // const bindData = async () => {
  //   try {
  //     let theEvent = props.event;
  //     if (theEvent.GUID) {
  //       let items = await db
  //         .table("flights")
  //         .where({ EventGUID: theEvent.GUID })
  //         .toArray();
  //       let filterDeleted = items.filter((x) => x.Deleted === null);
  //       filterDeleted = sortArray(filterDeleted, "DepartureDate");
  //       let filterDeletedGrouped = Object.values(
  //         groupBy(filterDeleted, "Connection")
  //       ); //Groupby connection
  //       // let filterDeletedGrouped =groupBy(filterDeleted, "Connection");//Groupby connection
  //       //Now just take one per connection
  //       let finalFlight = [];

  //       filterDeletedGrouped.forEach((item) => {
  //         let exist = finalFlight.filter(
  //           (x) => x.Connection === item[0].Connection
  //         )[0];
  //         if (!exist) {
  //           item[0].Count = item.length;
  //           if (item.length > 1) {
  //             //Add arrival from last flight
  //             //     item[0].To =
  //           }
  //           finalFlight.push(item[0]);
  //         }
  //       });

  //       setFlights(finalFlight);
  //       if (Flights.length === 0) {
  //         props.handleSidebarItem("EVENT_DETAILS", {});
  //       }
  //     }
  //   } catch (ex) {
  //     console.error(ex);
  //     debugger;
  //   }
  // };

  const intl = useIntl();

  const deleteAllSelected = () => {
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete all selected items",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then(async (result) => {
      try {
        if (result.value) {
          let query = {
            Items: "",
            Events: "",
          };
          state.SelectedItems.forEach(async (item) => {
            query.Items += (query.Items.length > 0 ? ";" : "") + item.GUID;

            // setFlights((prev) => {
            //   const newState = prev;
            //   return newState.filter((flight) => flight.GUID !== item.GUID);
            // });

            //Find the record locally and mark as removed
            let localRecord = await db
              .table("flights")
              .where({ GUID: item.GUID })
              .first();
            localRecord.Deleted = moment().format("YYYY-MM-DDTHH:mm:ss");

            await db.table("flights").put(localRecord);
          });
          //await bindData();//This is done to refresh the UI locally fast
          await api.post("/Flights/DeleteBatch", query);
          dispatch(getFlights(props.event.GUID));

          setState((prev) => {
            return {
              ...prev,
              SelectedItems: [],
            };
          });

          // setTimeout(() => {
          //   bindData();
          // }, 500);
        }
      } catch (ex) {
        console.error(ex);
      }
    });
  };

  const selectAll = () => {
    try {
      let SelectedItems = state.SelectedItems;
      let filter = GlobalFlights;
      let checkSelection = checkSelectionStatus(filter, null, SelectedItems);

      if (checkSelection === 0) {
        //We don't have anything,proceed to select all
        filter.forEach((item) => {
          if (!exist(SelectedItems, item)) {
            SelectedItems.push(item);
          }
        });
      } else if (checkSelection === 1) {
        //everyone is in here, deselect all from this team, for users it means mark as deleted, it doesn't matter how many records we re-create for this... is the safest way

        SelectedItems = [];
      } else {
        //is mixed selected the ones missed
        filter.forEach((item) => {
          if (!exist(SelectedItems, item)) {
            SelectedItems.push(item);
          } else {
            //Check if deleted
          }
        });
      }
      setState((prev) => {
        return {
          ...prev,
          SelectedItems: SelectedItems,
        };
      });
    } catch (ex) {
      console.error("Exception selecting all on flights", ex);
    }
  };

  const toggleSelect = (item, setFlightState) => {
    let SelectedItems = state.SelectedItems;
    if (exist(SelectedItems, item)) {
      if (
        SelectedItems.filter(
          (x) => x.GUID === item.GUID && x.Deleted === null
        )[0]
      ) {
        SelectedItems = SelectedItems.filter((x) => x.GUID !== item.GUID);
      }
    } else {
      SelectedItems.push(item);
    }
    setState((prev) => {
      return {
        ...prev,
        SelectedItems: SelectedItems,
      };
    });
  };

  const togglePrivateFlight = (reload) => {
    try {
      setState((prev) => {
        return {
          ...prev,
          UI: {
            ...prev.UI,
            showPrivateFlight: !prev.UI.showPrivateFlight,
          },
        };
      });

      if (reload) {
        dispatch(getFlights(props.event.GUID));
        // setTimeout(() => {
        //   bindData();
        // }, 500);
      }
    } catch (error) {
      console.error(error);
      debugger;
    }
  };
  const toggleCommercialFlight = (reload) => {
    try {
      setState((prev) => {
        return {
          ...prev,
          UI: {
            ...prev.UI,
            showCommercialFlight: !prev.UI.showCommercialFlight,
          },
        };
      });

      if (reload) {
        dispatch(getFlights(props.event.GUID));

        // setTimeout(() => {
        //   bindData();
        // }, 500);
      }
    } catch (ex) {
      console.error(ex);
      debugger;
    }
  };

  // useEffect(() => {
  //   dispatch(getFlights(props.event.GUID));
  //   // FlightsRemote();
  // }, []);

  // useEffect(() => {
  //   FlightsRemote();
  //   // bindData();
  // }, [GlobalFlights.length]);

  function setFlight() {
    try {
      if (
        GlobalFlights &&
        GlobalFlights.filter((x) => x.Deleted === null).length
      ) {
        props.handleSidebarItem(
          "FLIGHT_LIST",
          GlobalFlights.filter((x) => x.Deleted === null)[0]
        );
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  const toggleShare = (record) => {
    setState((prev) => {
      return {
        ...prev,
        sharingContent: record,
        UI: {
          ...prev.UI,
          showSharingHub: !prev.UI.showSharingHub,
        },
      };
    });
  };

  const { isChecked: isBeta } = useBetaFeature();

  const handleAddFlightOnEmpty = () => {
    const inputOptions = isBeta
      ? {
          Commercial: "Commercial",
          Private: "Private",
        }
      : {
          Commercial: "Commercial",
        };

    Swal.fire({
      title: `Select flight type`,
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      input: "radio",
      inputOptions: inputOptions,
      inputValidator: (value) => {
        if (!value) {
          return "Please select a flight type.";
        }
      },
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Close",
      customClass: {
        popup: "px-2 w-100 align-items-center fs-3",
        content: "m-0 p-0",
      },
    }).then((result) => {
      if (result.value === "Commercial") {
        toggleCommercialFlight(false);
      } else if (result.value === "Private") {
        togglePrivateFlight();
      }
    });
  };

  const { isChecked } = useBetaFeature();

  //Delete Selected Items on Delete Key Down
  useEffect(() => {
    const handleDeleteKey = ({ key }) => {
      if (key === "Delete") {
        deleteAllSelected();
      }
    };
    const rootElement = document.getElementById("root");
    rootElement.addEventListener("keydown", handleDeleteKey);

    return () => {
      rootElement.removeEventListener("keydown", handleDeleteKey);
    };
  }, [state.SelectedItems]);

  return (
    <>
      <ModalComponent
        modal={state.UI.showSharingHub}
        toggle={toggleShare}
        childeren={
          <SharingHub
            ViaEmail
            // ViaLink
            ViaClipboard
            Item={state.sharingContent}
            toggle={toggleShare}
            //    ParentSectionGUID={attachment.EventGUID}

            Type="FLIGHT"
            // sectionGUID={attachment.GUID}
          />
        }
      />

      <ModalComponent
        toggle={toggleExportToEvent}
        modal={state.UI.showExportToEvent}
        childeren={
          <ExportToEvent
            originEvent={props.originEvent}
            //  loading={loading}
            exportMode={state.UI.exportMode}
            items={state.SelectedItems}
            toggle={toggleExportToEvent}
            section="flight"
          />
        }
      />

      <ModalComponent
        modal={state.UI.showLabel}
        toggle={toggleShowLabel}
        childeren={
          <LabelForm
            //loading={loading}
            submitFunction={editLabel}
            resource={props.sideBarItemData}
            toggle={toggleShowLabel}
          />
        }
      />

      <ModalComponent
        modal={state.UI.showPrivateFlight}
        toggle={togglePrivateFlight}
        childeren={
          <AddEditPrivateFlights
            event={props.event}
            handleSidebarItem={props.handleSidebarItem}
            toggle={togglePrivateFlight}
            setFlights={setFlights}
          />
        }
      />

      <ModalComponent
        modal={state.UI.showCommercialFlight}
        toggle={toggleCommercialFlight}
        childeren={
          <AddEditFlights
            toggle={toggleCommercialFlight}
            event={props.event}
            handleSidebarItem={props.handleSidebarItem}
            setFlights={setFlights}
            // bindData={bindData}
          />
        }
      />
      <div className="resource__header " onClick={() => setFlight()}>
        <div className="row">
          <div className="col-auto px-0 py-1">
            <button
              className="btn btn-sm text-secondary"
              onClick={() => {
                if (GlobalFlights && GlobalFlights.length > 0) {
                  selectAll();
                }
              }}
              type="button"
            >
              {checkSelectionStatus(
                GlobalFlights,
                null,
                state.SelectedItems
              ) === 0 ? (
                <i className="far fa-square"></i>
              ) : checkSelectionStatus(
                  GlobalFlights,
                  null,
                  state.SelectedItems
                ) === 1 ? (
                <i className="fas fa-check-square"></i>
              ) : (
                <i className="fas fa-minus-square"></i>
              )}
            </button>
          </div>
          <div className="col-5 px-0 py-1">
            <div className="head-left pt-1">
              <h4>
                <FormattedMessage id="flights" defaultMessage="Flights" />
              </h4>
            </div>
          </div>
          <div className="col-auto px-0 pr-3 text-right ml-auto py-1">
            <div className="resource_button-right">
              <div className="filter-icon green d-none">
                <img src={IMAGES.FILTER} alt="dot" width="20" height="20" />
              </div>
              <div className="create-button" style={{ zIndex: "50" }}>
                <Popup
                  trigger={
                    <button type="button" className="btn btn-sm btn-primary">
                      <i className="fa fa-plus" />
                    </button>
                  }
                  style={{ zIndex: 100 }}
                  position="bottom right"
                  arrow={false}
                >
                  {(open) => (
                    <div>
                      <div
                        onClick={() => {
                          open();
                          toggleCommercialFlight(false);
                        }}
                        className="create-button mt-2"
                      >
                        <span className="btn btn-block btn-file">
                          <FormattedMessage
                            id="commercialFlight"
                            defaultMessage="Commercial Flight"
                          />
                        </span>
                      </div>

                      <div
                        onClick={() => {
                          open();
                          togglePrivateFlight();
                        }}
                        className={`create-button mt-2 beta ${
                          !isChecked && "active"
                        }`}
                      >
                        <span className="btn btn-block btn-file">
                          <FormattedMessage
                            id="privateFlight"
                            defaultMessage="Private Flight"
                          />
                          {isChecked && (
                            <span className="badge badge-info ml-2">Beta</span>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>
            </div>
          </div>
          {GlobalFlights &&
          GlobalFlights.length > 0 &&
          checkSelectionStatus(GlobalFlights, null, state.SelectedItems) !==
            0 ? (
            <div className="col-12 bg-subOption text-dark text-center py-2">
              <button
                className="btn btn-secondary mx-2 btn-sm"
                onClick={() => toggleExportToEvent("export")}
              >
                <FormattedMessage
                  id="moveToAnotherEvent"
                  defaultMessage="Move to another event"
                />
              </button>

              <button
                className="btn btn-danger mx-2 btn-sm"
                onClick={() => deleteAllSelected()}
              >
                <FormattedMessage
                  id="deleteSelected"
                  defaultMessage="Delete Selected"
                />
              </button>
            </div>
          ) : null}
        </div>
      </div>
      {GlobalFlights && GlobalFlights.length === 0 ? (
        <EmptyState
          image={EmptyStateImg}
          cta="Add a new flight"
          text="No flight added yet"
          actionBtn={{
            action: handleAddFlightOnEmpty,
            title: "Add Flights",
          }}
        />
      ) : (
        <div className="resource__body selectorContainer">
          <Scrollbars style={{ width: "100%", height: 250 }}>
            {GlobalFlights.map((flight, index) => {
              return (
                <FlightListItem
                  key={index}
                  flight={flight}
                  handleSidebarItem={props.handleSidebarItem}
                  selected={
                    props.sideBarItemData.Connection === flight.Connection
                  }
                  toggleNewMembersModal={props.toggleNewMembersModal}
                  deleteAllSelected={deleteAllSelected}
                  selectedItems={state.SelectedItems}
                  toggleSelect={toggleSelect}
                  checkSelectionStatus={checkSelectionStatus}
                  toggleShowLabel={toggleShowLabel}
                  shareFlight={shareFlight}
                  {...props}
                />
              );
            })}
          </Scrollbars>
        </div>
      )}
    </>
  );
};

export default FlightList;
