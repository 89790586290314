import React, { useEffect, useState } from "react";
import { organizationUsers } from "../../common/helpers";
import { getOrganization } from "../../utils";

import { Scrollbars } from "react-custom-scrollbars";
import { SimpleLoader } from "../common";

import api from "../../common/api";
import moment from "moment";
import uuid from "uuid";
import AssignMembersHotelUser from "./AssignMembersHotelUser";
import { useIntl, FormattedMessage } from "react-intl";

const AssignMembersHotel = (props) => {
  const [state, setState] = useState({
    TeamUsers: [],
    SelectedUsers: [],
    UI: {
      IsLoading: false,
      Search: "",
    },
  });
  const intl = useIntl();
  const [CheckInGroupalDate, setCheckInGroupalDate] = useState(null);
  const [CheckOutGroupalDate, setCheckOutGroupalDate] = useState(null);

  const [teams, setTeams] = useState([]);

  const bindData = async () => {
    try {
      console.log("enter on bindData");
      let theOrganization = getOrganization();
      if (theOrganization) {
        let users = await organizationUsers(theOrganization.GUID);
        setTeams(users);
        setState({
          ...state,
          TeamUsers: users,
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const requestUsersAssignedRemote = async () => {
    try {
      if (props.hotel) {
        let response = await api.get(
          "/hotels/GetStaff?hotelGUID=" + props.hotel.GUID
        );
        if (response.data.Status === "ok") {
          setState({
            ...state,
            SelectedUsers: response.data.Response,
          });

          //   console.log("register users selected from server", response.data, new Date())
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const exist = (staff) => {
    // return 0;
    try {
      //avoid to execute if the screen is not active
      if (!props.active) {
        return;
      }

      // console.log("check if exist", staff, new Date())

      let users = state.SelectedUsers;

      let record = users.findIndex(
        (x) => x.AccountGUID === staff.AccountGUID && x.Deleted === null
      );
      //    console.log("record  on hotel exist ", users, staff, record)
      return record >= 0;
    } catch (ex) {
      console.error(ex);
    }
  };

  const selectAll = (role) => {
    let SelectedUsers = state.SelectedUsers;
    let filter = role.Staffs.filter((x) => x.Deleted === null);
    let checkSelection = checkSelectionStatus(role);
    console.log("checking the status of the role ", role, checkSelection);
    if (checkSelection === 0) {
      //We don't have anything,proceed to select all
      filter.forEach((item) => {
        if (!exist(item)) {
          SelectedUsers.push({
            HotelGUID: props.hotel.GUID,
            GUID: uuid.v4(),
            AccountGUID: item.AccountGUID,
            Deleted: null,
            Room: "",
            Number: "",
            RoomType: "",
            CheckInDate: props.hotel.Checkin,
            CheckOutDate: props.hotel.Checkout,
          });
        } else {
          //Check if deleted
        }
      });
    } else if (checkSelection === 1) {
      //everyone is in here, deselect all from this team, for users it means mark as deleted, it doesn't matter how many records we re-create for this... is the safest way
      filter.forEach((item) => {
        if (exist(item)) {
          let indexFound = SelectedUsers.findIndex(
            (x) => x.AccountGUID === item.AccountGUID && x.Deleted === null
          );
          SelectedUsers[indexFound].Deleted = moment().format();
          //SelectedUsers.push({ HotelGUID: props.hotel.GUID, GUID: uuid.v4(), StaffGUID: item.GUID, Deleted: null });
        } else {
          //Check if deleted
        }
      });
    } else {
      //is mixed selected the ones missed
      filter.forEach((item) => {
        if (!exist(item)) {
          SelectedUsers.push({
            HotelGUID: props.hotel.GUID,
            GUID: uuid.v4(),
            AccountGUID: item.AccountGUID,
            Deleted: null,
            Room: "",
            Number: "",
            RoomType: "",
            CheckInDate: props.hotel.Checkin,
            CheckOutDate: props.hotel.Checkout,
          });
        } else {
          //Check if deleted
        }
      });
    }
    setState({
      ...state,
      SelectedUsers: SelectedUsers,
    });
  };

  //Update the record for the collection if needed
  const updateSelected = (obj) => {
    let SelectedUsers = state.SelectedUsers;
    SelectedUsers.forEach((item, index) => {
      if (item.AccountGUID === obj.AccountGUID && item.Deleted === null) {
        SelectedUsers[index] = obj;
      }
    });

    setState({
      ...state,
      SelectedUsers: SelectedUsers,
    });
    console.log("updating selected", obj, SelectedUsers, state.SelectedUsers);
  };

  //Update all the record for the collection if needed
  const updateAllSelected = (checkin, checkout) => {
    let SelectedUsers = state.SelectedUsers;
    SelectedUsers.forEach((item, index) => {
      if (checkin) {
        SelectedUsers[index].CheckInDate =
          moment(CheckInGroupalDate).format("YYYY-MM-DD");
      }
      if (checkout) {
        SelectedUsers[index].CheckOutDate =
          moment(CheckOutGroupalDate).format("YYYY-MM-DD");
      }
    });

    setState({
      ...state,
      SelectedUsers: SelectedUsers,
    });
  };

  const toggleMember = (staff) => {
    try {
      let checkExist = exist(staff);

      let SelectedUsers = state.SelectedUsers;
      if (checkExist) {
        if (
          SelectedUsers.filter(
            (x) => x.AccountGUID === staff.AccountGUID && x.Deleted === null
          )[0]
        ) {
          SelectedUsers.filter(
            (x) => x.AccountGUID === staff.AccountGUID && x.Deleted === null
          )[0].Deleted = moment().format("YYYY-MM-DD HH:mm:ss");
        }
      } else {
        let newItem = {
          HotelGUID: props.hotel.GUID,
          GUID: uuid.v4(),
          AccountGUID: staff.AccountGUID,
          //  StaffGUID: staff.GUID,
          Deleted: null,
          CheckInDate: props.hotel.Checkin,
          CheckOutDate: props.hotel.Checkout,
          Number: "",
          Room: "",
          RoomType: "",
        };

        SelectedUsers.push(newItem);
      }
      setState({
        ...state,
        SelectedUsers: SelectedUsers,
      });
    } catch (ex) {
      console.error(ex);
    }
    console.log("new state", state);
  };
  const loadUsersUI = (role) => {
    try {
      let parsedState = state;

      let filter = role.Staffs.filter((x) => x.Deleted === null);
      console.log("users to show for ", role, filter);
      if (state.UI.Search.length > 0) {
        filter = filter.filter(
          (x) =>
            x.Account.Name.toLowerCase().includes(state.UI.Search) ||
            (x.Account.LastName &&
              x.Account.LastName.toLowerCase().includes(state.UI.Search))
        );
      }
      let users = filter
        .sort((a, b) => a.Account.Name.localeCompare(b.Account.Name))
        .map((staff, index) => {
          let parsedStaff = staff; //JSON.parse(JSON.stringify(staff));

          let checkIfExist = exist(parsedStaff);

          let usersSelected = parsedState.SelectedUsers;
          let Reservation = null;
          if (checkIfExist) {
            let record = usersSelected.filter(
              (x) => x.AccountGUID === parsedStaff.AccountGUID
            )[0];
            Reservation = record;
            //   debugger;
          }
          return (
            <AssignMembersHotelUser
              key={index}
              doesExist={checkIfExist}
              selectedItems={usersSelected}
              parsedStaff={parsedStaff}
              hotel={props.hotel}
              toggleMember={toggleMember}
              updateSelected={updateSelected}
              Reservation={Reservation}
            />
          );
        });

      return users;
    } catch (ex) {
      console.error(ex);
      debugger;
      return <>Error</>;
    }
  };

  useEffect(() => {
    bindData();
    requestUsersAssignedRemote();
  }, []);

  const handleSubmit = async (skip) => {
    try {
      if (!skip) {
        setState({
          ...state,
          UI: {
            ...state.UI,
            IsLoading: !state.UI.IsLoading,
          },
        });
        if (state.SelectedUsers.length > 0) {
          let staff = state.SelectedUsers;
          let toSent = [];
          staff.forEach((item, index) => {
            if (item.CheckInDate === undefined) {
              item.CheckInDate = props.hotel.CheckInDate;
              item.CheckOutDate = props.hotel.CheckOutDate;
            }
            item.HotelGUID = props.hotel.GUID;
            toSent.push(item);
          });

          //Date was being added plus 1 day, so we need to normalize it.
          toSent = toSent.map((item) => ({
            ...item,
            CheckInDate: moment(item.CheckInDate).format("YYYY-MM-DD"),
            CheckOutDate: moment(item.CheckOutDate).format("YYYY-MM-DD"),
          }));

          await api.post("/HotelReservations/AddEditMultiple", toSent);
        }
        setState({
          ...state,
          UI: {
            ...state.UI,
            IsLoading: !state.UI.IsLoading,
          },
        });
      }
      props.toggle(true);

      if (props.parentToggle) {
        props.parentToggle(true);
      }
    } catch (ex) {
      console.error(ex);
      debugger;
    }
  };

  const checkSelectionStatus = (role) => {
    let filter = role.Staffs.filter((x) => x.Deleted === null);
    let count = 0;
    filter.forEach((item) => {
      if (exist(item)) {
        count++;
        //Check if deleted
      }
    });
    if (count === 0) {
      return 0; //none
    } else if (filter.length === count) {
      return 1; //total
    } else {
      return 2; //partial
    }
  };

  // if (!props.hotel) {
  //     console.log("Don't have the hotel obj on assign members hotel, closing", props);
  //     // props.toggle();
  //     return <>PROBLEM</>
  // }
  return (
    <div className={"organization-transparent"}>
      <div className="event-page">
        <div className="event-expennd-section hotel-members">
          <div className="text-head">
            <div className="row">
              <div className="text-left col">
                <h1 className="title d-inline">
                  <FormattedMessage
                    id="assignMembers"
                    defaultMessage="Assign Members"
                  />
                </h1>
                {props.hotel ? (
                  <small className="text-muted d-block">
                    {props.hotel.Name}
                    {props.hotel.CheckInDate ? (
                      <>
                        {" | " +
                          moment(props.hotel.CheckInDate).format(
                            "MMM DD, YYYY"
                          )}
                        {" | " +
                          moment(props.hotel.CheckOutDate).format(
                            "MMM DD, YYYY"
                          )}
                      </>
                    ) : props.hotel.Checkin ? (
                      <>
                        {" | " +
                          moment(props.hotel.Checkin).format("MMM DD, YYYY")}
                        {" | " +
                          moment(props.hotel.Checkout).format("MMM DD, YYYY")}
                      </>
                    ) : null}
                  </small>
                ) : null}
              </div>
              <div className="col-auto ml-auto"></div>
            </div>
          </div>
          <div className="px-1 py-3">
            <div className="row  containerFullHeight text-dark ">
              <div className="col-12 mx-0 px-0 selectorContainer">
                <Scrollbars style={{ height: "calc(100vh - 175px)" }}>
                  {teams.length > 0
                    ? teams.map((role, index) => {
                        try {
                          let allUsersSelected = checkSelectionStatus(role); //1 = all;2= partial;0 =none
                          return (
                            <div className="row px-0 " key={index}>
                              <div className="col-auto px-0 mx-0 bgTitleModal py-1 mt-4">
                                <button
                                  className="btn btn-sm text-secondary"
                                  onClick={() => selectAll(role)}
                                >
                                  {allUsersSelected === 0 ? (
                                    <i className="far fa-square uncheck"></i>
                                  ) : allUsersSelected === 1 ? (
                                    <i className="fas fa-check-square"></i>
                                  ) : (
                                    <i className="fas fa-minus-square"></i>
                                  )}
                                </button>
                              </div>
                              <div className="col bgTitleModal py-1 mt-4 ">
                                <h3 className="pt-2">{role.Name}</h3>
                              </div>

                              <div className="col-12 px-0 mx-0 selectorContainer">
                                {loadUsersUI(role)}
                              </div>
                            </div>
                          );
                        } catch (ex) {
                          console.error(ex);
                          debugger;
                        }
                      })
                    : null}
                </Scrollbars>
                <div className="submit-button text-right">
                  {state.UI.IsLoading ? (
                    <SimpleLoader loading={state.UI.IsLoading} />
                  ) : props.donable ? (
                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="btn btn-primary"
                    >
                      {state.SelectedUsers.filter((x) => x.Deleted === null)
                        .length > 0 ? (
                        "Done"
                      ) : (
                        <FormattedMessage id="Skip" defaultMessage="Skip" />
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="btn btn-primary"
                    >
                      <FormattedMessage id="done" defaultMessage="Done" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignMembersHotel;
