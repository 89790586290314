import React, { useState } from "react";
import { InformationOutline, CheckCircle } from "mdi-material-ui";
import UserTabs from "../organization/UserTabs";
import { DropDownComponent, ModalComponent } from "../common";
import moment from "moment";
import GeneralUserItem from "../common/GeneralUserItem";
import { randomImage } from "../common/randomImage";
import { useIntl, injectIntl, FormattedMessage } from "react-intl";
const UserItem = ({ user, deleteStaff, editStaff }) => {
  const [showProfile, setShowProfile] = useState(false);

  return (
    <div className="card shadow-sm mt-1 userTeam">
      <ModalComponent
        modal={showProfile}
        toggle={() => setShowProfile(!showProfile)}
        childeren={
          <UserTabs
            user={user.Account}
            toggle={() => setShowProfile(!showProfile)}
          />
        }
      />
      <div className="card-body p-1 row mx-0">
        <div className="col-4">
          <GeneralUserItem
            account={user.Account}
            showShortName={true}
            onClick={() => editStaff(user)}
          />
        </div>
        <div className="col-auto ml-auto px-0">
          <div className="row">
            <div className="px-0 pr-2 text-dark">
              {user.CheckInDate ? (
                <>
                  <small className="text-muted smallest">Check In</small>
                  <p>{moment(user.CheckInDate).format("MMM, DD")}</p>
                </>
              ) : null}
            </div>
            <div className="px-2 text-dark">
              {user.CheckOutDate ? (
                <>
                  <small className="text-muted smallest">Check Out</small>
                  <p>{moment(user.CheckOutDate).format("MMM, DD")}</p>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="row">
            <div className="px-2 text-dark">
              <small className="text-muted smallest">Rsv</small>
              <p>{user.Number || "N/A"}</p>
            </div>
            <div className="px-2 text-dark d-none">
              <p>Room</p>
              <p>
                <strong>{user.Room || "N/A"}</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="col-1 text-right px-0">
          <button className="teamItem__more btn-reset mx-2">
            <DropDownComponent
              data={[
                {
                  delete: false,
                  name: <FormattedMessage id="Edit" defaultMessage="Edit" />,
                  trigger: () => editStaff(user),
                },
                {
                  delete: true,
                  name: (
                    <FormattedMessage id="delete" defaultMessage="Delete" />
                  ),
                  trigger: () => deleteStaff(user),
                },
              ]}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserItem;
