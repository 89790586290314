import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { addActivitySchema } from "../../utils/validationsSchemas";
import { getBasicInfo } from "../../utils";
import { ToastNotificationSuccess, SimpleLoader } from "../common";

import moment from "moment";
import { CustomInput } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { ChevronDown } from "mdi-material-ui";
import { useIntl, FormattedMessage } from "react-intl";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const REMINDERS_OPTIONS = [
  {
    label: <FormattedMessage id="noReminder" defaultMessage="No reminder" />,
    value: null,
  },
  { label: "5 mins", value: 5 },
  { label: "15 mins", value: 15 },
  { label: "30 mins", value: 30 },
  { label: "45 mins", value: 45 },
  { label: "1 hour", value: 60 },
];

let now = new Date();
now.setHours(6);
now.setMinutes(0);
now.setMilliseconds(0);
let untilStart = new Date();
untilStart.setHours(7);
untilStart.setMinutes(0);
untilStart.setMilliseconds(0);

const ActivityForm = (props) => {
  let {
    event,
    scheduleMaster,
    toggle,
    addActivity,
    edit,
    activity,
    editActivity,
    expanded,
    loading,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("description").focus();
    if (activity !== undefined && activity.DateUntil === null) {
      setUntil(false);
      return;
    }
    if (activity !== undefined && activity.Date === activity.DateUntil) {
      setUntil(false);
      return;
    }
    if (activity !== undefined && activity.Date !== activity.DateUntil) {
      setUntil(true);
      return;
    }
  }, []);

  function addAndReset(activity, props) {
    if (!props.isValid) {
      return;
    }

    dispatch(
      addActivity(
        activity,
        () =>
          ToastNotificationSuccess(
            1,
            `${activity.Name} succesfully added to the itinerary`
          ),
        () => {
          props.resetForm();
          //  props.setFieldValue("Description", "", true);
          document.getElementById("description").focus();
        },
        until
      )
    );
  }

  function add(activity) {
    dispatch(
      addActivity(
        activity,
        () =>
          ToastNotificationSuccess(
            1,
            `${activity.Name} succesfully added to the itinerary`
          ),
        () => toggle(),
        until
      )
    );
  }

  function activityEdit(activity, until) {
    console.log({ThisiSIt: activity})
    dispatch(
      editActivity(
        activity,
        () =>
          ToastNotificationSuccess(1, `${activity.Name} succesfully edited`),
        () => toggle(),
        until
      )
    );
  }

  let activityObjectToEdit = activity
    ? {
        ...activity,
        Name: activity && activity.Name,
        StartDate: activity && moment(activity.Date).toDate(),
        StartTime: activity && moment(activity.Date).toDate(),
        UntilDate:
          activity && activity.DateUntil === null
            ? moment(activity.Date).toDate()
            : moment(activity.DateUntil).toDate(),
        UntilTime:
          activity && activity.DateUntil === null
            ? moment(activity.Date).add(1, "hour").toDate()
            : moment(activity.DateUntil).toDate(),
        Notes: activity && activity.Notes,
        EventGUID: activity && activity.EventGUID,
        ScheduleMasterGUID: activity && activity.ScheduleMasterGUID,
        CreatedUserGUID: activity && activity.CreatedUserGUID,
      }
    : null;

  let newActivity = {
    Name: "",
    StartTime:
      scheduleMaster.Schedules.length > 0
        ? scheduleMaster.Schedules[scheduleMaster.Schedules.length - 1]
            .DateUntil === null
          ? moment(
              scheduleMaster.Schedules[scheduleMaster.Schedules.length - 1].Date
            )
              .add(1, "hour")
              .toDate()
          : moment(
              scheduleMaster.Schedules[scheduleMaster.Schedules.length - 1]
                .DateUntil
            )
              .add(1, "hour")
              .toDate()
        : now,
    StartDate: moment(scheduleMaster.Date).format("YYYY-MM-DD"),
    UntilTime:
      scheduleMaster.Schedules.length > 0
        ? scheduleMaster.Schedules[scheduleMaster.Schedules.length - 1]
            .DateUntil
          ? moment(
              scheduleMaster.Schedules[scheduleMaster.Schedules.length - 1]
                .DateUntil
            )
              .add(1, "hours")
              .toDate()
          : moment(
              scheduleMaster.Schedules[scheduleMaster.Schedules.length - 1].Date
            )
              .add(1, "hours")
              .toDate()
        : untilStart,
    UntilDate: moment(scheduleMaster.Date).toDate(),
    Notes: "",
    EventGUID: event,
    ScheduleMasterGUID: scheduleMaster.GUID,
    CreatedUserGUID: getBasicInfo().GUID,
    Reminder: "",
  };

  const [until, setUntil] = useState(false);
  return (
    <div className={`${expanded ? "expanded" : ""}`}>
      <div
        className="event-page"
        style={{ overflowY: "revert", marginRight: "10%" }}
      >
        <div className="event-expennd-section activities">
          <div className="text-head">
            <div className="text-left">
              <h1>
                {edit ? (
                  <FormattedMessage
                    id="editActivity"
                    defaultMessage="Edit activity"
                  />
                ) : (
                  <FormattedMessage
                    id="addActivity"
                    defaultMessage="Add a new Activity"
                  />
                )}
              </h1>
              <p>{scheduleMaster.Name}</p>
            </div>

            <button
              type="button"
              className="btn btn-sm btn-link ml-2"
              onClick={() => toggle()}
            >
              <FontAwesomeIcon icon={faTimes} color="white" />
            </button>
          </div>
          <div className="activity__form">
            <Formik
              enableReinitialize={edit || true}
              onSubmit={(values) =>
                edit ? activityEdit(values, until) : add(values)
              }
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={addActivitySchema}
              initialValues={edit ? activityObjectToEdit : newActivity}
            >
              {(props) => (
                <Form autoComplete="off" className="pt-1">
                  <div>
                    <label htmlFor="description">
                      <FormattedMessage
                        id="description"
                        defaultMessage="Description"
                      />
                    </label>
                    <Field
                      id="description"
                      className="form-control"
                      type="text"
                      name="Name"
                      maxLength={50}
                      placeholder="Describe your activity"
                    />
                    {props.values.Name.length >= 50 && (
                      <small id="descriptionHelp" className="form-text text-danger">
                        Maximum characters: 50
                      </small>
                    )}

                    <ErrorMessage
                      className="error-message"
                      name="Name"
                      component="div"
                    />
                  </div>
                  <div className="row">
                    <div className="col">
                      <div>
                        <label htmlFor="startTime">
                          <FormattedMessage
                            id="startTime"
                            defaultMessage="Start Time"
                          />
                        </label>
                        {props.values && props.values.StartTime && (
                          <DatePicker
                            id={"startTime"}
                            selected={props.values.StartTime}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            onChange={(e) => {
                              props.setFieldValue("StartTime", e);
                            }}
                          />
                        )}
                        <ErrorMessage
                          className="error-message"
                          name="StartTime"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="col">
                      {true && (
                        <>
                          <label htmlFor={"text"}>
                            <FormattedMessage id="date" defaultMessage="Date" />
                          </label>
                          <div>
                            <input
                              type="date"
                              className="form-control underline"
                              value={moment(props.values.StartDate).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(e) => {
                                try {
                                  if (!e.target.value) {
                                    return;
                                  }
                                  let finalValue = e.target.value;
                                  props.setFieldValue(
                                    "StartDate",
                                    moment(finalValue).format("YYYY-MM-DD")
                                  );
                                  props.setFieldValue(
                                    "UntilDate",
                                    moment(e.target.value).format("YYYY-MM-DD")
                                  );
                                  // props.handleSubmit();
                                } catch (ex) {
                                  console.error(ex);
                                  debugger;
                                }
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <a className="clean-link show-more text-left">
                    <CustomInput
                      checked={until}
                      onChange={(e) => setUntil(!until)}
                      //   defaultChecked={until}
                      type="switch"
                      id={scheduleMaster.GUID}
                      name={`switch-${scheduleMaster.Name}`}
                      label={
                        until ? (
                          <FormattedMessage
                            id="HideDateTime"
                            defaultMessage="Hide End Date & Time"
                          />
                        ) : (
                          <FormattedMessage
                            id="ShowDateTime"
                            defaultMessage="Show End Date & Time"
                          />
                        )
                      }
                    />
                  </a>
                  {until ? (
                    <div className="row">
                      <div className="col">
                        <div>
                          <label htmlFor="untilTime">
                            <FormattedMessage
                              id="untilTime"
                              defaultMessage="Until Time"
                            />
                          </label>
                          {true && (
                            <DatePicker
                              id={"untilTime"}
                              selected={props.values.UntilTime}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              onChange={(e) => {
                                props.setFieldValue("UntilTime", e);
                                let now = moment(e).format("HH:mm a");
                                if (
                                  now.startsWith("00:") &&
                                  moment(props.values.StartDate).isSame(
                                    props.values.UntilDate,
                                    "day"
                                  )
                                ) {
                                  props.setFieldValue(
                                    "UntilDate",
                                    moment(props.values.UntilDate)
                                      .add(1, "days")
                                      .format("YYYY-MM-DD")
                                  );
                                }
                              }}
                            />
                          )}
                          <ErrorMessage
                            className="error-message"
                            name="UntilTime"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col">
                        {true && (
                          <>
                            <label htmlFor={"text"}>
                              <FormattedMessage
                                id="untilDate"
                                defaultMessage="Until Date"
                              />
                            </label>
                            <div>
                              <input
                                type="date"
                                className="form-control underline"
                                value={moment(props.values.UntilDate).format(
                                  "YYYY-MM-DD"
                                )}
                                onChange={(e) => {
                                  try {
                                    if (!e.target.value) {
                                      return;
                                    }
                                    let finalValue = e.target.value;
                                    props.setFieldValue(
                                      "UntilDate",
                                      moment(finalValue).format("YYYY-MM-DD")
                                    );

                                    //  props.handleSubmit();
                                  } catch (ex) {
                                    console.error(ex);
                                    debugger;
                                  }
                                }}
                              />
                              {/* <DatePicker
                                id={"untilDate"}
                                dateFormat="MMMM dd, yyyy"
                                showPopperArrow={false}
                                selected={moment(
                                  props.values.UntilDate
                                ).toDate()}
                                onChange={(e) => {
                                  props.setFieldValue(
                                    "UntilDate",
                                    moment(e).format("YYYY-MM-DD")
                                  );
                                }}
                              />
                              <div
                                className="drop-icon"
                                onClick={() =>
                                  document.getElementById("untilDate").focus()
                                }
                              >
                                <ChevronDown />
                              </div> */}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="row  mt-2">
                    <div className="col">
                      <div>
                        <label>
                          <FormattedMessage
                            id="Reminder"
                            defaultMessage="Reminder"
                          />
                        </label>
                        <Select
                          menuPlacement="top"
                          placeholder={
                            <FormattedMessage
                              id="chooseReminder"
                              defaultMessage="Choose a reminder"
                            />
                          }
                          name="Reminder"
                          value={
                            REMINDERS_OPTIONS
                              ? REMINDERS_OPTIONS.find(
                                  (o) => o.value === props.values.Reminder
                                )
                              : null
                          }
                          options={REMINDERS_OPTIONS}
                          onChange={(option) => {
                            props.setFieldValue("Reminder", option);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 mb-3 text-dark">
                    <label htmlFor="note">
                      <FormattedMessage id="note" defaultMessage="Note" />
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={props.values.Notes}
                      onChange={(val) => props.setFieldValue("Notes", val)}
                      //onBlur={()=>props.handleSubmit()}
                    />
                  </div>

                  <div className="d-flex align-items-center">
                    {edit ? (
                      <button type="submit" className="btn primary">
                        <FormattedMessage id="done" defaultMessage="Done" />
                      </button>
                    ) : loading ? (
                      <div className="text-center w-100">
                        <SimpleLoader loading={loading} />
                      </div>
                    ) : (
                      <>
                        <button
                          onClick={() =>
                            addAndReset(props.values, { ...props })
                          }
                          type="button"
                          className="btn secondary mr-2"
                          disabled={loading}
                        >
                          <FormattedMessage
                            id="doneContinue"
                            defaultMessage="Done & Continue"
                          />
                        </button>
                        <button
                          disabled={loading}
                          type="submit"
                          className="btn primary"
                        >
                          <FormattedMessage id="done" defaultMessage="Done" />
                        </button>
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityForm;
