import * as constant from "./constants";
import api from "../../../common/api";
import moment from "moment";
import uuid from "uuid";
import db from "../../../common/db";

export function addActivity(activity, success, toggle, until) {
  const url = "/Schedule/AddEdit";
  return async (dispatch) => {
    dispatch({
      type: constant.CREATING_ACTIVITY,
    });
    try {
      const request = await creatingActivityRequest(url, activity, until);

      const baseSchedule = await db
        .table("schedules")
        .where({ GUID: activity.ScheduleMasterGUID })
        .first();

      const localBase = {
        ...baseSchedule,
        Schedules: [...baseSchedule.Schedules, request.data],
      };
      const newSchedule = await db.table("schedules").put(localBase);
      dispatch({
        type: constant.CREATED_ACTIVITY_SUCCESS,
        data: request.data,
      });
      success();
      toggle();
    } catch (error) {
      console.error(error);
      debugger;
      dispatch({
        type: constant.CREATING_ACTIVITY_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_ACTIVITY: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_ACTIVITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        schedulesMasters: state.schedulesMasters.map((schedule) => {
          if (schedule.GUID === action.data.ScheduleMasterGUID) {
            schedule.Schedules.push(action.data);
          }

          return schedule;
        }),
      };
    }
    case constant.CREATING_ACTIVITY_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}

function creatingActivityRequest(url, activity, until) {
  let start = `${moment(activity.StartDate).format("YYYY-MM-DD")}T${moment(
    activity.StartTime
  ).format("HH:mm:ss")}`;
  let end = `${moment(
    activity.UntilDate ? activity.UntilDate : activity.StartDate
  ).format("YYYY-MM-DD")}T${moment(activity.UntilTime).format("HH:mm:ss")}`;
  const startDate = start;
  const dateUntil = end;
  return api.post(url, {
    Name: activity.Name,
    Date: startDate,
    Notes: activity.Notes,
    DateUntil: until ? dateUntil : null,
    GUID: uuid.v4(),
    CreatedUserGUID: activity.CreatedUserGUID,
    ScheduleMasterGUID: activity.ScheduleMasterGUID,
    Reminder: activity.Reminder.value,
  });
}
